<template>
  <div class="swaps">
    <div class="tile">
      <h5 class="tile-title mb-4">Swaps</h5>
      <vue-good-table ref="swapsTable" :rows="rows" :columns="columns" :pagination-options="{
        enabled: true,
      }" :search-options="{
  enabled: true,
  placeholder: 'Search',
}">
        <div slot="emptystate">
          <span>No data</span>
        </div>
      </vue-good-table>
    </div>
    <div class="tile">
      <ValidationObserver tag="form" v-slot="{ handleSubmit, valid }" ref="swapsForm" class="mt-0">
        <h5 class="tile-title mb-4">Upload Swap</h5>
        <ValidationProvider tag="div" rules="required" name="Title" v-slot="{ errors }" class="mb-3">
          <label for="title" class="col-form-label">Title:</label>
          <input type="text" class="form-control" :value="selectedFile.title" id="title" :class="{
            'form-control': true,
            'is-invalid': errors[0],
          }" readonly />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider ref="file" tag="div" rules="required|mimes:csv" name="file" v-slot="{ errors }" class="mb-3">
          <label for="file" class="col-form-label">CSV File:</label>
          <input id="swapFile" class="form-control" type="file" @change="checkFile" :class="{
            'form-control': true,
            'is-invalid': errors[0],
          }" />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-grid d-md-flex justify-content-md-end">
          <button class="btn btn-secondary me-md-2" type="button" @click.prevent="resetSwapsForm">
            Reset
          </button>
          <button type="submit" class="btn btn-primary me-md-2" :disabled="!valid || isLoading"
            @click.prevent="handleSubmit(uploadFile)">
            Upload
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
import loader from "../components/loader";
export default {
  data() {
    return {
      rows: [],
      isLoading: false,
      selectedFile: {
        title: "",
        file: null,
      },
      columns: [
        {
          label: "Symbol",
          field: "symbol",
        },
        {
          label: "Long",
          field: "long",
        },
        {
          label: "Short",
          field: "short",
        },
      ],
    };
  },
  components: {
    loader,
  },
  created() {
    this.getSwaps();
  },
  methods: {
    getSwaps() {
      this.$axios({
        url: "/swaps",
      })
        .then(({ data: { swapsList } }) => {
          this.rows = swapsList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async checkFile(event) {
      const { valid } = await this.$refs.file.validate(event);
      if (valid) {
        let file = event.target.files[0] || event.dataTransfer.files;
        this.selectFile(file);
      }
    },
    selectFile(file) {
      this.selectedFile.file = file;
      this.selectedFile.title = file.name;
    },
    uploadFile() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("title", this.selectedFile.title);
      formData.append("file", this.selectedFile.file);
      this.$axios
        .post("swaps/upload", formData)
        .then(({ data: { success, message, swapsList } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          mutations.showToast({ success, message });
          if (success) {
            this.resetSwapsForm();
            this.rows = swapsList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetSwapsForm() {
      this.$nextTick(() => {
        this.selectedFile = {
          title: "",
          file: null,
        };
        this.$refs.swapsForm.reset();
        this.$refs.file.syncValue(null);
        document.getElementById("swapFile").value = null;
      });
    },
  },
};
</script>