var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swaps"},[_c('div',{staticClass:"tile"},[_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Swaps")]),_c('vue-good-table',{ref:"swapsTable",attrs:{"rows":_vm.rows,"columns":_vm.columns,"pagination-options":{
        enabled: true,
      },"search-options":{
  enabled: true,
  placeholder: 'Search',
}}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('span',[_vm._v("No data")])])])],1),_c('div',{staticClass:"tile"},[_c('ValidationObserver',{ref:"swapsForm",staticClass:"mt-0",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, valid }){return [_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Upload Swap")]),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"tag":"div","rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"title"}},[_vm._v("Title:")]),_c('input',{staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"type":"text","id":"title","readonly":""},domProps:{"value":_vm.selectedFile.title}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"file",staticClass:"mb-3",attrs:{"tag":"div","rules":"required|mimes:csv","name":"file"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"file"}},[_vm._v("CSV File:")]),_c('input',{staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"id":"swapFile","type":"file"},on:{"change":_vm.checkFile}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-grid d-md-flex justify-content-md-end"},[_c('button',{staticClass:"btn btn-secondary me-md-2",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.resetSwapsForm.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('button',{staticClass:"btn btn-primary me-md-2",attrs:{"type":"submit","disabled":!valid || _vm.isLoading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.uploadFile)}}},[_vm._v(" Upload "),_c('loader',{attrs:{"isLoading":_vm.isLoading}})],1)])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }